<template>
  <div>
    <div class="theme-global">
      <HeaderWrapperLogic>
        <template #header="props">
          <HeaderDefault
            class="print:hidden"
            v-bind="{ ...props, currentBrand: 'global', staticOnMobile: true }"
          />
        </template>
        <template #navigation="props">
          <HeaderNavigationBar v-bind="props" />
        </template>
      </HeaderWrapperLogic>

      <div
        class="grid-standard bg-primitives-off-white py-lg md:py-xl lg:py-4xl print:py-0"
      >
        <main id="main" class="columns-standard grid">
          <slot />
        </main>
      </div>

      <div class="mb-[var(--navbar-height)] lg:mb-0 print:hidden">
        <FooterMain />
      </div>
    </div>
    <GlobalSlides />
  </div>
</template>
